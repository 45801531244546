import Image from "next/image";
import styled from "styled-components";
import ColdfishLogo from "../../assets/images/coldfish_no_bg.png";

export default function LoginScreen(props: { onClick: () => void }) {
  const { onClick } = props;
  return (
    <OuterContainer>
      <Container>
        <Image
          src={ColdfishLogo}
          alt="Coldfish logo"
          width={150}
          height={150}
        />
        <StyledHeading>coldfish</StyledHeading>
        <StyledLoginButton onClick={() => onClick()}>
          Kirjaudu
        </StyledLoginButton>
      </Container>
    </OuterContainer>
  );
}

const OuterContainer = styled.main`
  width: auto;
  height: 100vh;
  background: ${(props) => props.theme.linearGradient.login};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  margin: 0 auto;
  padding: 20vh 0;
`;

const StyledLoginButton = styled.button`
  padding: 1rem 2.5rem;
  border-radius: 40px;
  background-color: ${(props) => props.theme.secondary.middle};
  color: ${(props) => props.theme.primary.dark};
  border: 0px;
  font-weight: 600;

  margin-top: 2rem;

  :hover {
    background-color: ${(props) => props.theme.secondary.light};
  }
`;

const StyledHeading = styled.h1`
  color: ${(props) => props.theme.primary.middle};
  font-size: 2.5rem;
  line-height: 0.2;
`;
