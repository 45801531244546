import React, { createContext, useState } from "react";

export interface IDefaultConfig {
    pageName: string;
    setPageName: (name: string) => void;
}

const DEFAULT_CONFIG: IDefaultConfig = {
    pageName: "",
    setPageName: (name: string) => { }
};

export const Config = createContext<IDefaultConfig>(DEFAULT_CONFIG);

export default function ConfigContext(props: { children: React.ReactNode }): JSX.Element {
    const { children } = props;
    const [pageName, setPageName] = useState<string>("Coldfish");

    return (
        <Config.Provider value={{
            pageName,
            setPageName
        }}>
            {children}
        </Config.Provider>
    )
}