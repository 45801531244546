import Image from "next/image";
import { useRouter } from "next/router";
import { useContext, useEffect } from "react";
import styled from "styled-components"
import ListIcon from "../../assets/icons/ListIcon";
import Wordpress from "../../assets/icons/Wordpress";
import ColdfishLogo from "../../assets/images/coldfish_no_bg.png";
import { Config } from "../../contexts/Config";
import { SideMenuItem } from "../../models/SideMenuItem";

const LogoWrapper = styled.div`

`;

const StyledSideMenu = styled.section`
    min-height: 100vh;
    position: sticky;
    top: 0;
    background-color: ${(props) => props.theme.header};
    & > div:nth-of-type(1) {
        width: 50px;
        height: 50px;
    }
`;

const StyledNavItems = styled.div`
    padding-top: 3rem;
    text-align: center;

`;

const StyledNavItem = styled.div<{ active: boolean }>`
        cursor: pointer;
        padding: 1rem;
        transition: .24s;
        background: ${({ active }) => active ? '#5429ef' : 'transparent'};
`;

export default function SideMenu() {
    const router = useRouter();
    const { setPageName } = useContext(Config);
    const params = router.pathname;

    const sidemenu_items = [
        new SideMenuItem('Sovellusten hallinta', '/', <ListIcon />),
        new SideMenuItem('Wordpress hallinta', '/wordpress', <Wordpress />)
    ];

    useEffect(() => {
        const current_page = sidemenu_items.find((item) => {
            return item.path === params && item
        })
        if (!current_page) {
            return setPageName("Coldfish")
        }
        setPageName(current_page.label);
    }, [params])

    return (
        <StyledSideMenu>
            <div>
                <LogoWrapper>
                    <Image
                        src={ColdfishLogo}
                        alt="Coldfish Logo depicting an angry-looking blue fish"
                        width={50}
                        height={50}
                    />
                </LogoWrapper>
            </div>
            <StyledNavItems>
                {sidemenu_items.map((item) => {
                    return (
                        <StyledNavItem
                            onClick={() => router.push(item.path)}
                            active={params === item.path}
                            key={item.path}>
                            {item.icon}
                        </StyledNavItem>
                    )
                })}
            </StyledNavItems>
        </StyledSideMenu>
    )
}