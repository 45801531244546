export const styledTheme = {
  black: "#000000",
  white: "#ffffff",
  body: "#ffffff",
  text: "#000F47",
  header: "#2E1687",
  primary: {
    light: "#C2C2FF",
    middle: "#5429EF",
    dark: "#000F47",
  },
  secondary: {
    light: "#FFFBB8",
    middle: "#FFF764",
    dark: "#FFE457",
  },
  error: {
    middle: "#EA7651",
    light: "#FB906F",
  },
  success: {
    middle: "#27B180",
    light: "#3ED19C",
  },
  focus: "#FF1BA4",
  grey: {
    light: "#E1E1EC",
    middle: "#C2BFCF",
    dark: "#41444F",
    lightTransparent: "rgba(230, 231, 240, 0.5)",
  },
  boxShadow: {
    purpleLight: "rgba(84, 41, 239, 0.15)",
    blackMiddle: "rgba(0, 0, 0, 0.25)",
  },
  linearGradient: {
    login: "linear-gradient(180deg, #000f47 23.49%, #2e1687 100%)",
    main: "linear-gradient(112.87deg, #F2F2FE 0%, #D9D9FC 100%)",
  },
};

export const darkTheme = {
  black: "#000",
  white: "#fff",
  body: "#000f47",
  text: "#FFFBB8",
  header: "#2E1687",
  primary: {
    light: "#FFFBB8",
    middle: "#5429EF",
    dark: "#2E1687",
  },
  secondary: {
    light: "#FFFBB8",
    middle: "#FFF764",
    dark: "#FFE457",
  },
  error: {
    middle: "#EA7651",
    light: "#FB906F",
  },
  success: {
    middle: "#27B180",
    light: "#3ED19C",
  },
  focus: "#FF1BA4",
  grey: {
    light: "#E1E1EC",
    middle: "#C2BFCF",
    dark: "#41444F",
    lightTransparent: "rgba(0, 0, 0, 0.2)",
  },
  boxShadow: {
    purpleLight: "rgba(0, 0, 0, 0.15)",
    blackMiddle: "rgba(0, 0, 0, 0.25)",
  },
  linearGradient: {
    login: "linear-gradient(180deg, #000f47 23.49%, #2e1687 100%)",
    main: "linear-gradient(180deg, #000f47 23.49%, #2e1687 100%)",
  },
};
