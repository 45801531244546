import styled, { css } from "styled-components";

export const BaseButton = styled.button`
  font-size: 0.9rem;
  margin: 0.5rem 0;
  padding: 0.25rem 1em;
  border-radius: 4px;
  border: 0px;
  transition: 0.1s ease-in;
  font-weight: 600;
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 0.5em;

  background-color: ${(props) => props.theme.primary.light};
  color: ${(props) => props.theme.primary.dark};

  :hover {
    background-color: ${(props) => props.theme.primary.middle};
    color: ${(props) => props.theme.white};
  }

  :active {
    background-color: ${(props) => props.theme.primary.dark};
    color: ${(props) => props.theme.white};
  }

  :disabled {
    cursor: not-allowed;
    background-color: ${(props) => props.theme.grey.light};
    color: ${(props) => props.theme.grey.dark};
  }

  // for fontawesome icons
  > svg {
    vertical-align: 0;
  }
`;

export const ActionButton = styled(BaseButton)<{
  action?: "activate" | "passivate" | "refresh" | "loading";
}>`
  border-radius: 40px;
  margin: 0;

  ${(props) =>
    props.action == "activate" &&
    css`
      background-color: ${(props) => props.theme.success.middle};
      color: ${(props) => props.theme.white};

      :hover,
      :active {
        background-color: ${(props) => props.theme.success.light};
        color: ${(props) => props.theme.white};
      }
    `}

  ${(props) =>
    props.action == "passivate" &&
    css`
      background-color: ${(props) => props.theme.error.middle};
      color: ${(props) => props.theme.white};

      :hover,
      :active {
        background-color: ${(props) => props.theme.error.light};
        color: ${(props) => props.theme.white};
      }
    `}


  ${(props) =>
    props.action == "refresh" &&
    css`
      background-color: ${(props) => props.theme.primary.middle};
      color: ${(props) => props.theme.white};

      :hover,
      :active {
        background-color: ${(props) => props.theme.primary.light};
        color: ${(props) => props.theme.primary.dark};
      }
    `}


  ${(props) =>
    props.action == "loading" &&
    css`
      background-color: ${(props) => props.theme.primary.middle};
      color: ${(props) => props.theme.white};

      @keyframes rotate {
        0%,
        to {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(-365deg);
        }
      }

      > i {
        animation: rotate 2s linear infinite;
      }

      :hover,
      :active {
        background-color: ${(props) => props.theme.primary.light};
        color: ${(props) => props.theme.primary.dark};
      }
    `}
`;

export const StatusIndicator = styled.div<{ active?: boolean }>`
  width: 16px;
  height: 16px;
  background-color: ${(props) => props.theme.error.middle};
  border-radius: 40px;

  ${(props) =>
    props.active &&
    css`
      background-color: ${(props) => props.theme.success.middle};
    `}
`;
