import React from "react";

export interface ISideMenuItem {
  label: string;
  path: string;
  icon: React.ReactNode;
}

export class SideMenuItem implements ISideMenuItem {
  label: string;
  path: string;
  icon: React.ReactNode;
  constructor(label: string, path: string, icon: React.ReactNode) {
    this.label = label;
    this.path = path;
    this.icon = icon;
  }
}
