import styled from "styled-components";

type Props = {
  children: any;
};

export function Header(props: Props) {
  const { children } = props;

  return (
    <StyledHeader>
      {children}
    </StyledHeader>
  );
}

const StyledHeader = styled.header`
  min-height: 60px;
  padding: 1rem 1rem 0.5rem;
  display: flex;
  justify-content: space-between;
  background-color: #FFFFFF;
  box-shadow: 2px 4px 8px 3px ${(props) => props.theme.boxShadow.purpleLight},
    0px 2px 4px ${(props) => props.theme.boxShadow.blackMiddle};
  & > h3 {
  }  
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem; ;
`;

const StyledH1 = styled.h1`
  font-size: 1.3rem;
  letter-spacing: 0.1rem;
  text-transform: lowercase;
  color: ${(props) => props.theme.primary.light};
`;
