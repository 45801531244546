import Head from "next/head";
import { useContext } from "react";
import styled from "styled-components"
import { Config } from "../../contexts/Config";
import { Header } from "../Header";
import SideMenu from "../SideMenu";
import { BaseButton } from "../StyledButtons";
import { signIn, signOut, useSession } from "next-auth/react";
import LoginScreen from "../LoginScreen";

const StyledMain = styled.main`
    width: 100%;
    background: ${(props) => props.theme.linearGradient.main};

    height: 100%;
    & > div {
        padding: 1rem;
    }
`;

const StyledContentWrapper = styled.div`
    display: flex;
`;

const StyledPageContent = styled.main`
    width: 100%;
    min-height: 100vh;
`;

export default function SessionLayout(props: { children: JSX.Element }): JSX.Element {
    const { children } = props;
    const { pageName } = useContext(Config);
    const {data: session} = useSession();

    if (session) {
        return (
            <>
                <Head>
                    <title>Coldfish</title>
                    <meta name="description" content="Generated by create next app" />
                    <link rel="icon" href="/favicon.ico" />
                    <link
                        rel="apple-touch-icon"
                        sizes="180x180"
                        href="/apple-touch-icon.png"
                    />
                    <link
                        rel="icon"
                        type="image/png"
                        sizes="32x32"
                        href="/favicon-32x32.png"
                    />
                    <link
                        rel="icon"
                        type="image/png"
                        sizes="16x16"
                        href="/favicon-16x16.png"
                    />
                    <link
                        rel="icon"
                        type="image/png"
                        sizes="512x512"
                        href="/android-chrome-512x512.png"
                    />
                    <link
                        rel="icon"
                        type="image/png"
                        sizes="192x192"
                        href="/android-chrome-192x192.png"
                    />
                    <link rel="manifest" href="/site.webmanifest" />
                </Head>
                <StyledContentWrapper>
                    <SideMenu />
                    <StyledPageContent>
                        <Header>
                            <h3>
                                {pageName}
                            </h3>
                            <BaseButton onClick={() => signOut()}>Kirjaudu ulos</BaseButton>
                        </Header>
                        <StyledMain>
                            <div>
                                {children}
                            </div>
                        </StyledMain>
                    </StyledPageContent>
                </StyledContentWrapper>
            </>
        )
    } else {
        return <LoginScreen onClick={() => signIn()} />;
    }
}