import "../styles/globals.css";
import type { AppProps } from "next/app";
import { SessionProvider } from "next-auth/react";
import { styledTheme, darkTheme } from "../utils/styledTheme";
import { ThemeProvider } from "styled-components";
import { useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import SessionLayout from "../components/SessionLayout";
import ConfigContext from "../contexts/Config";

const queryClient = new QueryClient();

function MyApp({ Component, pageProps }: AppProps) {
  const { session, ...rest } = pageProps as unknown as any;
  const [theme, setTheme] = useState("light");
  const themeToggler = () => {
    theme === "light" ? setTheme("dark") : setTheme("light");
  };

  return (
    <SessionProvider session={session}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={styledTheme}>
          <ConfigContext>
            <SessionLayout>
              <Component {...rest} />
            </SessionLayout>
          </ConfigContext>
        </ThemeProvider>
      </QueryClientProvider>
    </SessionProvider>
  );
}

export default MyApp;
